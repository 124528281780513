@import './medapp-colors.scss';

$base-accent: map-get($medapp-palette, blue3);
$base-text-color: #fff;
$base-border-color: #e0e0e0;
$base-border-radius: 2px;

$background-color: map-get($medapp-palette, blue1);
$dark-background-color: map-get($medapp-palette, blue0);

$header-height: 50px;
