@import '../../themes/medapp.base.scss';
@import '../../dx-styles.scss';

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: $header-height;

  .dx-toolbar,
  .dx-toolbar .dx-lookup-field,
  .dx-toolbar .dx-lookup-arrow {
    color: $base-text-color;
  }

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-text-color;
  }

  .header-toolbar {
    height: 100%;
    background: $background-color;

    .dx-texteditor-input {
      color: $base-text-color;
    }

    .app-title {
      .dx-button-text {
        color: $base-text-color;
        font-size: 18px;
        font-weight: 500;
        text-transform: inherit;
      }
    }

    .dx-dropdowneditor-icon,
    .dx-menu-item-content,
    .dx-icon {
      color: white;
    }

    .dx-menu-item-expanded {
      .dx-item-content {
        color: $background-color;
      }
      .dx-icon {
        color: $background-color;
      }
    }

    .dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
      color: $background-color;
    }

    .dx-texteditor.dx-editor-underlined::after {
      border-bottom: none !important;
    }
  }

  .dx-toolbar-items-container {
    height: 100%;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}
