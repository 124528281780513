@-moz-document url-prefix() {
  .dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
    position: relative;
  }

  .dx-scheduler-work-space-month .dx-scheduler-date-table-cell .disable-date {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
  display: inline-block;
  width: 19%;
}

.options-container {
  display: flex;
  align-items: center;
}

.disable-date {
  background-image: repeating-linear-gradient(
    135deg,
    rgb(235, 235, 235),
    rgb(235, 235, 235) 4px,
    transparent 4px,
    transparent 9px
  ) !important;
  color: #9b6467 !important;
  pointer-events: none !important;
}

.date-is-national-holiday {
  background-image: repeating-linear-gradient(
    135deg,
    rgb(254, 189, 189),
    rgb(253, 198, 198) 4px,
    transparent 4px,
    transparent 9px
  ) !important;
  color: #9b6467 !important;
  pointer-events: none !important;
}

.dx-scheduler-date-table-cell {
  padding: 0;
  opacity: 1;
}

.time-style {
  opacity: 1 !important;
}

.dx-scheduler-appointment {
  border-radius: 15px !important;
  white-space: 'pre-wrap' !important;
}

.dx-scheduler-header .dx-toolbar .dx-toolbar-item-content,
.dx-scheduler-header .dx-toolbar .dx-toolbar-menu-container:nth-child(2) {
  padding: 0 !important;
}

.dx-scheduler-header-panel-empty-cell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dx-scheduler-header-panel-empty-cell img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
