.dx-scheduler-timeline .dx-scheduler-group-header {
  min-height: 150px;
}

.dx-scheduler:not(.dx-scheduler-native) .dx-scheduler-timeline .dx-scheduler-date-table .dx-scheduler-date-table-row {
  min-height: 150px;
}

div.dx-scheduler-group-header {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.nameAndPhotoDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.nameDisplayDiv {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
