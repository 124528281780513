@import '../../themes/medapp.base.scss';

.side-nav-outer-toolbar {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
}

.drawer {
  height: calc(100% - #{$header-height});
}

.layout-header {
  z-index: 1501;
}

.container-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.content {
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  min-height: 10vh;

  & .content-block {
    height: calc(100% - 40px);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  & .content-block-flex {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.footer-content {
  flex-basis: auto;
  flex-shrink: 0;
}

.container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

#navigation-header {
  background-color: $base-accent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .menu-button .dx-icon {
    color: $base-text-color;
  }

  .screen-x-small & {
    padding-left: 20px;
  }

  .dx-theme-generic & {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
